@import 'noop';
@import 'no-media';
@import 'parser';
@import 'slice';
@import 'to-length';
@import 'to-number';

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

// replace substring with another string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}

@function alpha($color, $opacity){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-h: var(#{$color+'-h'});
	$color-s: var(#{$color+'-s'});
	$color-l: var(#{$color+'-l'});
	@return hsla($color-h, calc(#{$color-s} * 1%), calc(#{$color-l} * 1%), $opacity);
}

@function lightness($color, $lightnessMultiplier){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-h: var(#{$color+'-h'});
	$color-s: var(#{$color+'-s'});
	$color-l: var(#{$color+'-l'});
	@return hsl($color-h, calc(#{$color-s} * 1%), calc(#{$color-l} * #{$lightnessMultiplier} * 1%));
}

@function saturation($color, $saturationMultiplier){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-h: var(#{$color+'-h'});
	$color-s: var(#{$color+'-s'});
	$color-l: var(#{$color+'-l'});
	@return hsl($color-h, calc(#{$color-s} * #{$saturationMultiplier} * 1%), calc(#{$color-l} * 1%));
}

@function text-contrast($color){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-l: var(#{$color+'-l'});
	@return hsl(0, 0%, calc((#{$color-l} - 60) * -100%));
}

@function text-contrast-sass($n) {
	$color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
	$light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);

	@if abs($color-brightness) < ($light-color/2) {
		@return white;
	}

	@else {
		@return black;
	}
}