@import 'log';
@import 'media';
@import 'tweakpoints';
@import 'media-config';
@import 'media';

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
	#{$context} & {
		@content;
	}
}

@mixin defineColorHSL($color, $hue, $saturation, $lightness, $createContrastVar: false){
	#{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{strip-unit($saturation)};#{$color}-l: #{strip-unit($lightness)};
	@if $createContrastVar {
		#{$color}-contrast: text-contrast(unquote("var(#{$color})"));
	}
}