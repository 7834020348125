%modalWrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: var(--layerModalZIndex);
	background: var(--colorShadeBg);
	pointer-events: none;
	transition: var(--animationBase);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	opacity: 0;
	visibility: hidden;
	padding: var(--spaceSm);
	perspective: 250px;

	&.is-active {
		pointer-events: auto;
		opacity: 1;
		visibility: visible;
	}
}

%modalContent {
	transform: translateY(80%) rotateX(-110deg) translateZ(-200px) scale(0.8);
	transform-origin: top center;
	transition: all 0.4s cubic-bezier(.64,-.19,.23,1.33);
	background: var(--colorThemeAccent);
	padding: var(--spaceMd);
	position: relative;
	max-width: 100%;
	margin: auto;
	opacity: 0;
}

%modalContent-active {
	transform: translateY(0) rotateX(0) translateZ(0) scale(1);
	opacity: 1;
}

%modalContentCross {
	position: absolute;
	top: 0;
	right: 0;
	background: var(--colorBrand);
	color: text-contrast(var(--colorBrand));
	width: 3.2em;
	height: 3.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: var(--animationBase);

	@include on-event {
		background: lightness(var(--colorBrand), 0.7);
	}
}
