@import "abstracts/_abstracts";
.ListProductItem {
    border: 1px solid var(--colorOutlines);
    border-radius: 0.5rem;
    padding: 1.5rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;

    &-image {
        position: relative;
        height: 12rem;
        border-radius: 0.5rem;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-title {
        margin: 0;
        font-size: 1.375rem;
        line-height: 1.09;
        font-weight: 800;
    }

    &-manufacturer {
        font-size: 1rem;
        line-height: 1.5;
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5rem;

        a {
            text-decoration: underline;
            transition: var(--animationBase);
        }
    }

    &-detail {

    }

    &-demand {
        color: var(--colorText);
        font-size: 1rem;
        line-height: 1.5;
        display: flex;
        gap: 0.5rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
            color: var(--colorBrand);
        }
    }

    &-bottom {
        display: flex;
        flex-flow: column;
        gap: 1rem;
        margin-top: auto;
    }
}