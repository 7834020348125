// Base path for assets (fonts, images...),
$base-url: '/frontend/' !default;

/// Native `url(..)` function wrapper
@function asset($base, $type, $path) {
	@return url($base + $type + $path);
}

/// Returns URL to an image based on its path
@function image($path, $base: $base-url) {
	@return asset($base, 'images/', $path);
}

/// Returns URL to a font based on its path
@function font($path, $base: $base-url) {
	@return asset($base, 'fonts/', $path);
}