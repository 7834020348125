$breakpoints: (
	'xxs': 25rem, // ~400px
	'xs': 32rem, // ~512px
	'sm': 48rem, // ~768px
	'md': 64rem, // ~1024px
	'lg': 80rem, // ~1280px
	'xl': 90rem  // ~1440px
) !default;

$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx)'
) !default;


$unit-intervals: (
	'px': 1,
	'em': 0.01,
	'rem': 0.01,
	'': 0
) !default;


$im-media-support: true !default;

$im-no-media-breakpoint: 'desktop' !default;

$im-no-media-expressions: ('screen', 'portrait', 'landscape') !default;